<template>
  <!-- 3.1-2-1 (1) -->
  <st-form-title :formTitle="$t('studio.business_bank_setting.individual.question1')" required />
  <radio-group
    name="individual.license"
    :options="optionCheckCert"
    :optionProps="{
      size: 'sm',
      align: 'middle'
    }"
    :rules="{
      required: $t('studio.business_bank_setting.individual.question1_msg1')
    }"
    class="flex flex-wrap gap-x-24 mt-12"
    :disabled="disabled"
  />
</template>

<script lang="ts" setup>
import StFormTitle from '@/components/common/st-form-title.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useI18n } from 'vue-i18n';
import { Confirmation } from '@/enums/common.enum';
import type { FormOption } from '@/types/common/form.type';

defineProps<{
  disabled?: boolean;
}>();

const optionCheckCert: FormOption[] = [
  {
    value: Confirmation.YES,
    label: 'studio.business_bank_setting.individual.select_y'
  },
  {
    value: Confirmation.NO,
    label: 'studio.business_bank_setting.individual.select_n'
  }
];
</script>
